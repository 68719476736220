import React from 'react'
import styled from 'styled-components'
import { Toaster } from 'react-hot-toast'

// Components
import Layout from '../components/Layout'
import ContactForm from '../components/Form/ContactForm'

const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: ${({ theme }) => theme.breakpoints.sm};
  padding: 0 ${({ theme }) => theme.spacings.large};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => theme.spacings.small};
  }
`

const Plunge = styled.p`
  color: ${({ theme }) => theme.colors.jet};
  font-size: ${({ theme }) => theme.desktopSizes.p};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`

const Contact = () => {
  return (
    <Layout title="Contact" subTitle="Get in touch">
      <Toaster position="top-center" reverseOrder={false} />
      <Wrapper>
        <Plunge>
          Ready to take the plunge? Got any questions, inquiries, feedback, or just want to say
          “hi?” Drop the Web Mobsters crew a line anytime via the contact form below:
        </Plunge>
        <ContactForm />
      </Wrapper>
    </Layout>
  )
}

export default Contact
