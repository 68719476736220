import React from 'react'
import styled from 'styled-components'

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BtnContainer = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.pink};
  border-radius: ${({ theme }) => theme.spacings.xxSmall};
  padding-top: ${({ theme }) => theme.spacings.small};
  padding-right: ${({ theme }) => theme.spacings.medium};
  padding-bottom: ${({ theme }) => theme.spacings.small};
  padding-left: ${({ theme }) => theme.spacings.medium};

  &:focus {
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    transition: ${({ theme }) => theme.animations.trans3};
    background: ${({ theme }) => theme.colors.indigo};
  }
`

const SubmitBtn = ({ text }) => {
  return (
    <BtnWrapper>
      <BtnContainer type="submit">{text}</BtnContainer>
    </BtnWrapper>
  )
}

export default SubmitBtn
