import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

// Styled Components
import { FormLabel, Required } from './FormElements'

const BoxWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`

const FormText = styled(Field)`
  width: 100%;
  resize: vertical;
  min-height: ${({ theme }) => theme.spacings.xxLarge};
  padding: ${({ theme }) => theme.spacings.xSmall};
  background: ${({ theme }) => theme.colors.cultured};
  border: 1px solid ${({ theme }) => theme.colors.cultured};
  margin-top: ${({ theme }) => theme.spacings.xSmall};
  border-radius: ${({ theme }) => theme.spacings.xSmall};

  ::placeholder {
    color: ${({ theme }) => theme.colors.spanish};
  }

  :focus {
    outline: none !important;
    color: ${({ theme }) => theme.colors.davys};
    transition: ${({ theme }) => theme.animations.trans3};
    border: 1px solid ${({ theme }) => theme.colors.indigo};
    box-shadow: ${({ theme }) => theme.shadows.shad2};
  }
`

const TextGroup = ({ label, name, placeholder, required, min = 10, max = 100 }) => {
  return (
    <BoxWrapper>
      <FormLabel htmlFor={name}>
        {label}
        {required && <Required>*</Required>}
      </FormLabel>
      <FormText
        component="textarea"
        id={name}
        name={name}
        placeholder={placeholder}
        minLength={min}
        maxLength={max}
        required={required}
        rows="6"
      />
    </BoxWrapper>
  )
}

export default TextGroup
