import styled from 'styled-components'

export const FormLabel = styled.label`
  color: ${({ theme }) => theme.colors.davys};
`

export const Required = styled.span`
  vertical-align: super;
  color: ${({ theme }) => theme.colors.pink};
  margin-left: 0;
`
