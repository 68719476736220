import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const BoxWrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacings.xxSmall} 0;
`

const FormInput = styled(Field)`
  display: none;

  & :not(:checked) + label:hover {
    transition: all 0.3s;
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.pink};
    background: ${({ theme }) => theme.colors.pink};
  }

  label:active,
  & :checked + label {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.indigo};
    border: 2px solid ${({ theme }) => theme.colors.indigo};
  }
`

const FormLabel = styled.label`
  cursor: pointer;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacings.medium};
  color: ${({ theme }) => theme.colors.davys};
  background: ${({ theme }) => theme.colors.cultured};
  border: 2px solid ${({ theme }) => theme.colors.cultured};
  padding: ${({ theme }) => theme.spacings.xSmall} ${({ theme }) => theme.spacings.small};
  margin: 0 ${({ theme }) => theme.spacings.xSmall};
`

const Checkbox = ({ name, value, label }) => {
  return (
    <BoxWrapper>
      <FormInput type="checkbox" name={name} value={value} id={value} />
      <FormLabel htmlFor={value}>{label}</FormLabel>
    </BoxWrapper>
  )
}

export default Checkbox
