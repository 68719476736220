import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import toast from 'react-hot-toast'

// Components
import Input from './Input'
import SubmitBtn from './SubmitBtn'
import Checkbox from './Checkbox'
import Radio from './Radio'
import TextGroup from './TextGroup'

// Util
import contactBack from '../../utils/contactBack'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  span {
    font-style: italic;
    color: ${({ theme }) => theme.colors.indigo};
  }
`

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacings.small};
`

const Note = styled.div`
  color: ${({ theme }) => theme.colors.davys};
  font-size: ${({ theme }) => theme.desktopSizes.small};
`

const FormName = styled.div`
  color: ${({ theme }) => theme.colors.davys};
  margin-bottom: ${({ theme }) => theme.spacings.small};

  small {
    color: ${({ theme }) => theme.colors.jet};
  }
`

const serviceOptions = [
  { value: 'web', label: 'Web' },
  { value: 'app', label: 'App' },
  { value: 'brand', label: 'Brand' },
  { value: 'print', label: 'Print' },
  { value: 'ebook', label: 'eBook' },
]

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const formName = 'contact-form'

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        services: [],
        budget: '',
        name: '',
        email: '',
        message: '',
        timeFrame: '',
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'contact-form', ...values }),
        })
          .then(() => {
            toast.success('Successfully sent email!')
            resetForm()
          })
          .catch(() => {
            toast.error('Error sending email!')
          })
          .finally(() => setSubmitting(false))
      }}
    >
      {() => (
        <Form method="post" netlify-honeypot="bot-field" data-netlify="true" name={formName}>
          <Container>
            <FormName id="services-group">Services</FormName>
            <Boxes role="group" aria-labelledby="services-group">
              {Object.keys(serviceOptions).map((item, key) => (
                <React.Fragment key={key}>
                  <Checkbox
                    name="services"
                    value={serviceOptions[item].value}
                    label={serviceOptions[item].label}
                  />
                </React.Fragment>
              ))}
            </Boxes>
          </Container>
          <Container>
            <FormName id="radio-budget">
              Budget <small>(in USD)</small>
            </FormName>
            <Boxes role="group" aria-labelledby="radio-budget">
              <Radio
                name="budget"
                data={[
                  { id: 'x1k', label: '1k - 2k' },
                  { id: 'x2k', label: '2k - 5k' },
                  { id: 'x5k', label: '5k - 10k' },
                  { id: 'x10k', label: '10k - more' },
                ]}
              />
            </Boxes>
            <Input name="name" placeholder="Full Name" required />
            <Input name="email" type="email" placeholder="Email Address" required />
            <TextGroup
              name="message"
              label="Project details or message"
              placeholder="Your project details or message"
              max="500"
              required
            />
            <TextGroup
              name="timeFrame"
              label="Timeframe"
              placeholder="Expected timeframe of completion or go live"
              min={3}
            />
            <Note>
              Please allow up to two business days (<span>{contactBack()}</span>) before we contact
              you back.
            </Note>
          </Container>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={formName} />
          <SubmitBtn text="Send Email" />
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
