import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

// Styled Components
import { FormLabel, Required } from './FormElements'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`

const FormInput = styled(Field)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xSmall};
  background: ${({ theme }) => theme.colors.cultured};
  border: 1px solid ${({ theme }) => theme.colors.cultured};
  margin-top: ${({ theme }) => theme.spacings.xSmall};
  border-radius: ${({ theme }) => theme.spacings.xSmall};

  ::placeholder {
    color: ${({ theme }) => theme.colors.spanish};
  }

  :focus {
    outline: none !important;
    color: ${({ theme }) => theme.colors.davys};
    transition: ${({ theme }) => theme.animations.trans3};
    border: 1px solid ${({ theme }) => theme.colors.indigo};
    box-shadow: ${({ theme }) => theme.shadows.shad2};
  }
`

const Input = ({ name, type = 'text', placeholder, required }) => {
  return (
    <Wrapper>
      <FormLabel htmlFor={name}>
        {placeholder}
        {required && <Required>*</Required>}
      </FormLabel>
      <FormInput
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        minLength="4"
        required={required}
      />
    </Wrapper>
  )
}

export default Input
